<template>
  <div
    class="max-w-wide-desktop
           mx-auto flex
           flex-col
           md:grid
           md:grid-cols-2
           px-0
           justify-center
           items-center"
  >
    <div class="flex flex-col justify-center items-center px-14 py-14">
      <h2 class="text-center mb-4">
        {{ hiddenLesson.title }}
      </h2>
      <div class="text-center mb-14" v-html="hiddenLesson.body"></div>
    </div>
    <img
      class="mx-auto w-full"
      :src="hiddenLesson.image"
      :alt="hiddenLesson.imageAltText"
    />
  </div>
</template>
<script>
export default {
  props: {
    hiddenLesson: {
      type: Object,
      required: true
    }
  }
};
</script>
