<template>
  <div class="w-full pt-4">
    <template v-if="discoverPage">
      <ExplanatoryCard
        class="mb-10 md:mt-4"
        v-if="isFinishedLoadingUser && !isLogged"
      />
      <div class="grid grid-cols-2 gap-2 my-8 px-4 md:px-0">
        <MetricsBox
          iconPath="assets/img/icons/time.svg"
          :label="$t('message.minutes-given')"
          :value="discoverPage.globalTimeGiven || 0"
        />
        <MetricsBox
          iconPath="assets/img/icons/heart.svg"
          :label="$t('message.lives-impacted')"
          :showInfoIcon="true"
          :value="discoverPage.globalLivesImpacted || 0"
        />
      </div>
      <div class="h-full sm:mb-12 px-4 md:px-0" :class="{ 'mt-10': isLogged }">
        <template v-if="kenticoTeams">
          <DiscoverItem
            v-for="(kenticoTeam, index) in kenticoTeams"
            :key="'team-' + index"
            :class="{ 'mt-8': index > 0 }"
            :team="kenticoTeam"
          >
          </DiscoverItem>
          <HomeFooter></HomeFooter>
        </template>
        <template v-else> <HomeInPageSkeleton /> </template>
      </div>
    </template>
  </div>
</template>
<script>
import HomeInPageSkeleton from "@/components/skeletons/HomeInPageSkeleton.vue";
import DiscoverItem from "@/components/DiscoverItem.vue";
import { handleHomepageToast } from "@/utils/homepageToaster.js";
import { KENTICO_GET_DISCOVER_TEAMS } from "@/kentico-api/teams.js";
import { seoMetadata } from "@/utils/seoMetadata";
import HomeFooter from "@/components/home/HomeFooter.vue";
import ExplanatoryCard from "@/components/home/ExplanatoryCard.vue";
import { mapGetters } from "vuex";
import MetricsBox from "@/components/shared/MetricsBox.vue";

export default {
  name: "HomeIn",
  components: {
    MetricsBox,
    HomeInPageSkeleton,
    DiscoverItem,
    HomeFooter,
    ExplanatoryCard
  },
  apollo: {
    discoverPage: {
      client: "kenticoClient",
      query: KENTICO_GET_DISCOVER_TEAMS,
      variables: {
        codename: "discover_page"
      },
      update(data) {
        return data.discoverPage;
      }
    }
  },
  mounted() {
    handleHomepageToast(this);
  },
  computed: {
    ...mapGetters("auth", ["isLogged", "isFinishedLoadingUser"]),
    kenticoTeams() {
      if (this.discoverPage?.teams?.items) {
        return this.discoverPage.teams.items;
      }
      return [];
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
    }
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaDescription: this.$t("message.default-og-description"),
      metaTitle: this.$t("message.default-og-title"),
      metaImageUrl: process.env.VUE_APP_WEB_URL + "/img/share-logo.png"
    });
  }
};
</script>
