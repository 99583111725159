<template>
  <div class="h-full w-full flex flex-col max-w-desktop px-6">
    <LogoHeader
      v-if="showLogoHeader"
      :showLogo="false"
      :showBackButton="showBackButton"
    >
      <div class="flex flex-col">
        <h3 class="capitalize mx-auto mb-4">{{ $t("message.get-started") }}</h3>
        <Logo
          class="m-auto"
          color="pink"
          @click="$router.push({ name: 'HomeIn' })"
        />
      </div>
    </LogoHeader>
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import LogoHeader from "@/components/shared/LogoHeader.vue";
import Logo from "@/components/Logo.vue";

export default {
  name: "SignUp",
  components: {
    LogoHeader,
    Logo
  },
  mounted() {
    if (this.$store.getters["auth/isLogged"]) {
      this.$router.push({ name: "HomeIn" }).catch(() => {});
    }
    if (this.$route.path === "/register") {
      this.$router.replace({
        name: "SignUpEmailForm",
        query: this.$route.query
      });
    }
  },
  computed: {
    showLogoHeader() {
      return this.$route.meta.showLogoHeader ?? true;
    },
    showBackButton() {
      return this.$route.meta.showLogoHeader ?? true;
    }
  }
};
</script>

<style lang="postcss" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
