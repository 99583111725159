<template>
  <div
    :key="task.id"
    class="task flex flex-col w-48 mr-4 md:mr-6 justify-start mb-2 cursor-pointer"
  >
    <router-link
      class="image rounded-sm w-48 relative bg-no-repeat bg-center bg-cover overflow-hidden"
      :style="backgroundImage"
      :to="{
        name: 'TaskPage',
        params: {
          teamId: task.progress.teamId,
          taskId: task.progress.taskId
        }
      }"
    >
      <div class="bg-shadow"></div>
      <TaskStatusBadge
        v-if="task.progress.status === 'complete'"
        :taskType="task.taskType"
        :status="task.progress.status"
        :count="task.progress.taskCompletionsCount"
      />
      <h5
        class="text-gray-50 capitalize w-full text-center bottom-4 absolute"
        v-if="!task.note"
      >
        {{ task.coverHeading }}
      </h5>
    </router-link>
    <div class="h-24">
      <LivesImpactedPill
        :text="getPointsText(task)"
        :showInfo="false"
        class="mt-2"
      />
      <router-link
        :to="{
          name: 'TaskPage',
          params: {
            teamId: task.progress.teamId,
            taskId: task.progress.taskId
          }
        }"
      >
        <h5 id="task-title" class="text-black h-10 mt-2 text-sm">
          {{ task.coverContent }}
        </h5>
      </router-link>
    </div>
  </div>
</template>
<script>
import TaskStatusBadge from "@/components/shared/TaskStatusBadge.vue";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";
import defaultCurrency, { pound } from "@/constants/defaultCurrency.js";

export default {
  name: "TaskLink",
  components: {
    TaskStatusBadge,
    LivesImpactedPill
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImage() {
      if (!this.task.coverImage || this.task.coverImage.url === "") return "";

      return `background-image: url('${this.task.coverImage.url}')`;
    }
  },
  methods: {
    formatPointsOnCompletion,
    getPointsText(task) {
      if (task.taskType === "DonationTask") {
        return `${defaultCurrency.pointsPerUnit.short} per ${defaultCurrency.symbol}`;
      } else if (task.taskType === "DonorboxTask") {
        return `${this.formatPointsOnCompletion(task.pointsOnCompletion)} per ${
          pound.symbol
        }`;
      } else {
        return this.formatPointsOnCompletion(task.pointsOnCompletion) || 0;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.bg-shadow {
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 94%) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  height: 71px;
  width: 100%;
}

.image {
  aspect-ratio: 1/1;
}

@supports not (aspect-ratio: 1/1) {
  .image {
    @apply h-48;
  }
}

#task-title {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
