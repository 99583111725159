<template>
  <div class="h-full">
    <template v-if="task">
      <component
        src="https://donorbox.org/widget.js"
        paypalExpress="false"
        :is="'script'"
      ></component>
      <div
        class="flex flex-col md:grid md:gap-4 md:mt-6"
        :class="{
          'md:grid-cols-2': showImage,
          'h-full': !showImage && !isDesktop
        }"
      >
        <BackButton
          v-if="isDesktop"
          id="back-button"
          @click="goBack()"
          :text="backButtonText"
          type="dark"
          class="col-span-2 w-auto mr-auto"
        />
        <div
          id="position-buttons"
          v-if="!isDesktop"
          class="absolute top-0 w-full pt-16 z-10"
        >
          <div class="relative flex flex-row justify-end px-4">
            <CloseButton
              @click="
                $router.push({
                  name: 'TeamPage'
                })
              "
              type="white"
            />
          </div>
        </div>
        <div v-if="showImage" class="main-image">
          <VideoRender
            :videoUrl="videoUrl"
            :coverImgUrl="imageUrl"
            class="md:rounded-md"
          >
            <div
              class="h-full bg-cover bg-center md:rounded-md"
              :style="`background-image: url('${imageUrl}');`"
            />
          </VideoRender>
        </div>
        <div
          class="main-info md:rounded-md"
          :class="{
            'bg-white': isDesktop,
            'desktop-single-pane-height': isDesktop && !showImage
          }"
        >
          <div
            class="flex flex-col p-4 md:p-10"
            :class="{
              'w-1/2 mx-auto': !showImage && isDesktop,
              'h-full': !showImage,
              'pb-0': !isDesktop
            }"
          >
            <LivesImpactedPill :text="pillText" class="mb-4 mx-auto" />

            <!-- status campaign pill -->
            <!-- hide on step -->
            <taskDonorboxContent
              :isStepInProgress="false"
              :isStepStart="true"
              :isStepComplete="false"
              :task="task"
            ></taskDonorboxContent>
            <div>
              <hr class="my-4" />
              <div
                class="flex
                       flex-col
                       justify-center
                       items-center
                       gap-4
                       mb-4
                       text-center"
              >
                <h2 v-if="this.isStepStart">
                  {{ callToAction }}
                </h2>
                <div v-if="isStepStart" class="flex flex-col gap-4">
                  <router-link
                    class="start-btn btn btn-default"
                    :to="{
                      name: 'SignInPage',
                      query: { returnURL: currentUrl }
                    }"
                  >
                    {{ $t("message.log-in-or-register") }}
                  </router-link>
                  <button
                    @click="step = 'inProgress'"
                    class="start-btn btn btn-clear"
                  >
                    {{ $t("message.continue-as-guest") }}
                  </button>
                </div>
              </div>
            </div>
            <template v-if="isStepInProgress">
              <div
                v-if="donorboxCampaignUrl !== ''"
                class="flex flex-col justify-center w-full items-center"
              >
                <iframe
                  :src="donorboxCampaignUrl"
                  name="donorbox"
                  allowpaymentrequest="allowpaymentrequest"
                  seamless="seamless"
                  frameborder="0"
                  scrolling="no"
                  height="900px"
                  width="100%"
                  style="max-width: 427px; min-width: 250px; max-height:none!important"
                ></iframe>
              </div>
            </template>
            <template v-else-if="isStepComplete">
              <TaskShare
                :shareImageUrl="metaData.imageUrl || imageUrl"
                class="mb-8"
              >
                <SocialShareGeneric
                  v-if="teamUrl && shareData"
                  class="mt-8 z-10"
                  :url="teamUrl"
                  :shareText="
                    kenticoTask.taskShareTitle !== ''
                      ? kenticoTask.taskShareTitle
                      : 'share'
                  "
                  :data="shareData"
                  :hashtags="
                    kenticoTask.taskShareHashtags !== ''
                      ? kenticoTask.taskShareHashtags
                      : 'share'
                  "
                  type="task-completed"
                />
              </TaskShare>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <TaskPageSkeleton />
    </template>
  </div>
</template>

<script>
import TaskPageSkeleton from "@/components/skeletons/TaskPageSkeleton.vue";
import BackButton from "@/components/shared/BackButton.vue";
import CloseButton from "@/components/shared/CloseButton.vue";
import VideoRender from "@/components/shared/VideoRender.vue";
import SocialShareMixin from "@/mixins/SocialShareMixin.js";
import taskDonorboxContent from "./taskDonorbox/ContentBox.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import TaskShare from "./TaskShare.vue";
import { mapGetters } from "vuex";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";
import { pound } from "@/constants/defaultCurrency.js";
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";

export default {
  name: "TaskDonorbox",
  mixins: [SocialShareMixin],
  components: {
    taskDonorboxContent,
    LivesImpactedPill,
    BackButton,
    CloseButton,
    VideoRender,
    TaskPageSkeleton,
    SocialShareGeneric,
    TaskShare
  },
  props: {
    appsyncTask: {
      type: Object,
      required: true
    },
    kenticoTask: {
      type: Object,
      required: true
    },
    shareData: {
      type: Object,
      required: true
    },
    metaData: {
      type: Object,
      required: true
    },
    isMember: {
      type: Boolean,
      required: false
    },
    viewMode: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      actionButtonBusy: false,
      taskCompletionConfirmed: false,
      step: "start", //complete",
      taskShareTitle: "I have finished this task, join us and make an impact",
      taskShareHashtags: ""
    };
  },
  beforeMount() {
    if (this.isLogged) {
      this.step = "inProgress";
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    ...mapGetters("auth", ["isLogged"]),
    currentUrl() {
      return this.$route.fullPath;
    },
    callToAction() {
      if (this.kenticoTask.callToAction === "") {
        return this.$t("message.start-donation");
      }
      return this.kenticoTask.callToAction;
    },
    task() {
      if (!this.appsyncTask || !this.kenticoTask) return null;
      return {
        ...this.appsyncTask,
        ...this.kenticoTask
      };
    },
    teamUrl() {
      if (!this.task?.team?.slug) return false;

      let props = this.$router.resolve({
        name: "TeamPage",
        params: {
          slug: this.task?.team?.slug || ""
        },
        query: {
          sharedFromTask: this.appsyncTask.kenticoCodename
        }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    isStepStart() {
      return this.step === "start";
    },
    isStepInProgress() {
      return this.step == "inProgress";
    },
    isStepComplete() {
      return this.step === "complete";
    },
    imageUrl() {
      if (this.isStepStart || this.isStepInProgress) {
        return this.task.introImage?.url || "";
      } else if (this.isStepComplete) {
        return this.task.completeImage?.url || "";
      }
      return "";
    },
    videoUrl() {
      if (this.isStepStart || this.isStepInProgress) {
        return this.task.introVideo?.url || "";
      } else if (this.isStepComplete) {
        return this.task.completeVideo?.url || "";
      }
      return "";
    },
    backButtonText() {
      if (this.isStepStart || this.isStepInProgress) {
        return this.task.team.name;
      } else {
        return "Back";
      }
    },
    showImage() {
      return this.isStepStart || this.isStepComplete || this.isStepInProgress;
    },
    donorboxCampaignSlug() {
      if (!this.task?.team?.slug) return null;

      let url = `${this.task.kenticoCodename}`.replaceAll("/", "-");
      return url;
    },
    donorboxCampaignUrl() {
      if (!this.task?.team?.slug) return null;
      let url = `${this.task.kenticoCodename}`.replaceAll("/", "-");
      return `https://donorbox.org/embed/${url}?default_interval=o&hide_donation_meter=true`;
    },
    pillText() {
      if (!this.task) return "";

      return `${this.formatPointsOnCompletion(
        this.appsyncTask.donorboxPointsMultiplier
      )} per ${pound.symbol}`;
    }
  },
  methods: {
    formatPointsOnCompletion,
    parsedContent(src) {
      return src.replace(/\n/g, "<br />");
    },
    goBack() {
      if (this.isStepStart || this.isStepInProgress) {
        // go back to team page
        this.$router.push({ name: "TeamPage" });
      } else {
        this.goBackToStart();
      }
    },
    goBackToStart() {
      this.paymentStep = "start";
    }
  }
};
</script>

<style lang="postcss" scoped>
.main-image {
  height: 33rem;
}

.desktop-single-pane-height {
  min-height: 44rem;
}

.bg-controls {
  width: 100%;
  height: 180px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  transform: rotate(-180deg);
}

@media screen and (min-width: 768px) {
  .main-image {
    height: 561px;
  }
}

.start-btn {
  min-width: 200px;
}
</style>
