<template>
  <div
    class="max-w-wide-desktop
           mx-auto
           flex
           flex-col
           py-24
           px-4
           justify-center
           items-center
           overflow-x-hidden"
  >
    <h2 class="mb-4">
      {{ extra.title }}
    </h2>
    <div class="body mb-14" v-html="extra.body"></div>
    <img class="w-11/12" :src="extra.image" :alt="extra.imageAltText" />
  </div>
</template>
<script>
export default {
  props: {
    extra: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang="postcss" scoped>
h2 {
  text-align: center;
}
.body {
  max-width: 800px;
  @apply w-full;
  text-align: center;
  font-size: 24px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 16px;
  }
}
img {
  min-width: 750px;
  max-width: 1100px;
}
</style>
