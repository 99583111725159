<template>
  <div id="landing-page" class="w-full bg-white">
    <template v-if="campaign">
      <div
        class="head flex flex-row px-6 py-1 h-20 justify-between items-center bg-white"
      >
        <Logo
          tabindex="0"
          color="pink"
          class="logo block cursor-pointer"
          @click="goHome()"
        />
        <div>
          <router-link
            v-if="!isLogged"
            :to="{ name: 'SignUpEmailForm' }"
            class="btn btn-default btn-thin px-2 w-36 md:w-48"
          >
            {{ $t("message.campaign.sign-up-button") }}
          </router-link>
          <router-link v-else :to="{ name: 'MyProfilePage' }">
            <Avatar
              :displayName="currentUser.attributes.displayName"
              :profileImageUrl="currentUser.attributes.profileImageUrl"
            />
          </router-link>
        </div>
      </div>
      <div
        :style="`background-color: ${campaign.general.backgroundColor}`"
        class="head-gol-text w-full text-center py-4 text-black gol-title"
      >
        {{ campaign.general.topTitle }}
      </div>
      <MainBanner :banner="campaign.banner"></MainBanner>
      <Carousel
        v-if="campaign.carouselItems.length > 0"
        :carouselItems="campaign.carouselItems"
        :carouselIntervalInSeconds="campaign.carouselIntervalInSeconds"
      />
      <HiddenLessons :hiddenLesson="campaign.hiddenLesson" />
      <CampaignStep
        v-for="(step, index) in campaign.howItWorks"
        :style="backgroundColorStep(index)"
        :key="index"
        :step="step"
      >
      </CampaignStep>
      <loginBlock
        style="background-color: #EBEBEB;"
        class="bg-gray-50"
        :title="campaign.signUp.title"
        :subtitle="campaign.signUp.subtitle"
        :titleLogged="campaign.signUp.loggedTitle"
        :subtitleLogged="campaign.signUp.loggedSubtitle"
        :teamImages="campaign.signUp.teamImages"
      />
      <Extra
        :style="`background-color: ${campaign.general.backgroundColor}`"
        :extra="campaign.extra"
      ></Extra>
      <div class="bg-white py-28">
        <HomeFooter />
      </div>
    </template>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
import Avatar from "@/components/shared/Avatar.vue";
import { seoMetadata } from "@/utils/seoMetadata";
import { mapGetters } from "vuex";
import {
  KENTICO_GET_CAMPAIGN2_DATA,
  formatKenticoCampaign2
} from "@/kentico-api/campaigns.js";
import HomeFooter from "@/components/home/HomeFooter.vue";
import loginBlock from "@/components/campaign/Block5.vue";
import CampaignStep from "@/components/campaign2/CampaignStep.vue";
import Extra from "@/components/campaign2/Extra.vue";
import HiddenLessons from "@/components/campaign2/HiddenLessons.vue";
import Carousel from "@/components/campaign2/Carousel.vue";
import MainBanner from "@/components/campaign2/MainBanner.vue";

export default {
  name: "Campaign",
  components: {
    Logo,
    HomeFooter,
    Avatar,
    loginBlock,
    CampaignStep,
    Extra,
    HiddenLessons,
    Carousel,
    MainBanner
  },
  data() {
    return {
      campaign: null,
      path: this.$route.params.slug
    };
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaTitle: this.title,
      metaImageUrl: this.image
    });
  },
  computed: {
    ...mapGetters("auth", ["isLogged", "currentUser"])
  },
  mounted() {
    this.loadCampaign(this.$route.fullPath);
  },
  methods: {
    backgroundColorStep(index) {
      if (index % 2 === 1) {
        return `background-color: ${this.campaign.general.backgroundColor}`;
      } else {
        return "background-color: #FAF9F8";
      }
    },
    goHome() {
      this.$router.push({ name: "HomeIn" }).catch(() => {});
    },
    loadCampaign() {
      this.$apollo
        .query({
          client: "kenticoClient",
          query: KENTICO_GET_CAMPAIGN2_DATA,
          variables: {
            path: this.path
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          if (response.data.campaign2_All.items.length > 0) {
            this.campaign = formatKenticoCampaign2(
              response.data.campaign2_All.items[0]
            );
          } else {
            this.$router.replace("/404");
          }
        });
    }
  }
};
</script>
<style lang="postcss">
#landing-page {
  .max-landing-content {
    max-width: 730px;
  }
}
.gol-title {
  @apply px-4 md:px-0;

  text-align: center;
  font-size: 28px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -1.44px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
}
</style>
