var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.task)?[_c('script',{tag:"component",attrs:{"src":"https://donorbox.org/widget.js","paypalExpress":"false"}}),_c('div',{staticClass:"flex flex-col md:grid md:gap-4 md:mt-6",class:{
        'md:grid-cols-2': _vm.showImage,
        'h-full': !_vm.showImage && !_vm.isDesktop
      }},[(_vm.isDesktop)?_c('BackButton',{staticClass:"col-span-2 w-auto mr-auto",attrs:{"id":"back-button","text":_vm.backButtonText,"type":"dark"},on:{"click":function($event){return _vm.goBack()}}}):_vm._e(),(!_vm.isDesktop)?_c('div',{staticClass:"absolute top-0 w-full pt-16 z-10",attrs:{"id":"position-buttons"}},[_c('div',{staticClass:"relative flex flex-row justify-end px-4"},[_c('CloseButton',{attrs:{"type":"white"},on:{"click":function($event){return _vm.$router.push({
                name: 'TeamPage'
              })}}})],1)]):_vm._e(),(_vm.showImage)?_c('div',{staticClass:"main-image"},[_c('VideoRender',{staticClass:"md:rounded-md",attrs:{"videoUrl":_vm.videoUrl,"coverImgUrl":_vm.imageUrl}},[_c('div',{staticClass:"h-full bg-cover bg-center md:rounded-md",style:(("background-image: url('" + _vm.imageUrl + "');"))})])],1):_vm._e(),_c('div',{staticClass:"main-info md:rounded-md",class:{
          'bg-white': _vm.isDesktop,
          'desktop-single-pane-height': _vm.isDesktop && !_vm.showImage
        }},[_c('div',{staticClass:"flex flex-col p-4 md:p-10",class:{
            'w-1/2 mx-auto': !_vm.showImage && _vm.isDesktop,
            'h-full': !_vm.showImage,
            'pb-0': !_vm.isDesktop
          }},[_c('LivesImpactedPill',{staticClass:"mb-4 mx-auto",attrs:{"text":_vm.pillText}}),_c('taskDonorboxContent',{attrs:{"isStepInProgress":false,"isStepStart":true,"isStepComplete":false,"task":_vm.task}}),_c('div',[_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"flex\n                     flex-col\n                     justify-center\n                     items-center\n                     gap-4\n                     mb-4\n                     text-center"},[(this.isStepStart)?_c('h2',[_vm._v(" "+_vm._s(_vm.callToAction)+" ")]):_vm._e(),(_vm.isStepStart)?_c('div',{staticClass:"flex flex-col gap-4"},[_c('router-link',{staticClass:"start-btn btn btn-default",attrs:{"to":{
                    name: 'SignInPage',
                    query: { returnURL: _vm.currentUrl }
                  }}},[_vm._v(" "+_vm._s(_vm.$t("message.log-in-or-register"))+" ")]),_c('button',{staticClass:"start-btn btn btn-clear",on:{"click":function($event){_vm.step = 'inProgress'}}},[_vm._v(" "+_vm._s(_vm.$t("message.continue-as-guest"))+" ")])],1):_vm._e()])]),(_vm.isStepInProgress)?[(_vm.donorboxCampaignUrl !== '')?_c('div',{staticClass:"flex flex-col justify-center w-full items-center"},[_c('iframe',{staticStyle:{"max-width":"427px","min-width":"250px","max-height":"none!important"},attrs:{"src":_vm.donorboxCampaignUrl,"name":"donorbox","allowpaymentrequest":"allowpaymentrequest","seamless":"seamless","frameborder":"0","scrolling":"no","height":"900px","width":"100%"}})]):_vm._e()]:(_vm.isStepComplete)?[_c('TaskShare',{staticClass:"mb-8",attrs:{"shareImageUrl":_vm.metaData.imageUrl || _vm.imageUrl}},[(_vm.teamUrl && _vm.shareData)?_c('SocialShareGeneric',{staticClass:"mt-8 z-10",attrs:{"url":_vm.teamUrl,"shareText":_vm.kenticoTask.taskShareTitle !== ''
                    ? _vm.kenticoTask.taskShareTitle
                    : 'share',"data":_vm.shareData,"hashtags":_vm.kenticoTask.taskShareHashtags !== ''
                    ? _vm.kenticoTask.taskShareHashtags
                    : 'share',"type":"task-completed"}}):_vm._e()],1)]:_vm._e()],2)])],1)]:[_c('TaskPageSkeleton')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }