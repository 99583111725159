import gql from "graphql-tag";

export const KENTICO_GET_CAMPAIGN = gql`
  query getCampaign($path: String!) {
    campaign_All(where: { block1Path: { eq: $path } }) {
      items {
        block1MainImage {
          image {
            url
          }
          imageAltText
          imageMobile {
            url
          }
        }
        block1Path
        block1Title
        block2Title
        block2BodyContent {
          html
        }
        block2BodyTitle
        block3TitleStepSpan
        block3Title
        block3Body {
          html
        }
        block3Image {
          url
        }
        block3ImageAltText
        block4TitleStepSpan
        block4Title
        block4Body {
          html
        }
        block4TaskImages {
          items {
            altText
            image {
              url
            }
          }
        }
        block5Subtitle
        block5Title
        block5SubtitleLogged
        block5TitleLogged
        block5TeamImages {
          items {
            image {
              url
            }
            altText
          }
        }
      }
    }
  }
`;

export const formatKenticoCampaign = kenticoCampaign => {
  const campaign = kenticoCampaign.campaign_All.items[0];

  const block1 = {
    title: campaign.block1Title,
    mainImage: {
      url: campaign.block1MainImage.image.url,
      mobileUrl: campaign.block1MainImage.imageMobile.url,
      altText: campaign.block1MainImage.imageAltText
    }
  };

  const block2 = {
    title: campaign.block2Title,
    bodyTitle: campaign.block2BodyTitle,
    body: campaign.block2BodyContent.html
  };

  const block3 = {
    title: campaign.block3Title,
    titleSpan: campaign.block3TitleStepSpan,
    body: campaign.block3Body.html,
    image: {
      url: campaign.block3Image.url,
      altText: campaign.block3ImageAltText
    }
  };

  const block4 = {
    title: campaign.block4Title,
    titleSpan: campaign.block4TitleStepSpan,
    body: campaign.block4Body.html,
    taskImages: campaign.block4TaskImages.items.map(item => {
      return {
        altText: item.altText,
        url: item.image.url
      };
    })
  };

  const block5 = {
    title: campaign.block5Title,
    subtitle: campaign.block5Subtitle,
    titleLogged: campaign.block5TitleLogged,
    subtitleLogged: campaign.block5SubtitleLogged,
    teamImages: campaign.block5TeamImages.items.map(item => {
      return {
        altText: item.altText,
        url: item.image.url
      };
    })
  };

  return {
    block1,
    block2,
    block3,
    block4,
    block5
  };
};
export const KENTICO_GET_CAMPAIGN2_DATA = gql`
  query campaignData($path: String!) {
    campaign2_All(where: { path: { eq: $path } }) {
      items {
        bannerCtaBackgroundColor
        bannerCtaRedirection
        bannerCtaText
        bannerCtaTextColor
        bannerTextColor
        bannerBody {
          html
        }
        bannerImage {
          url
        }
        bannerImageAltText
        bannerTitle
        backgroundColor
        extraBody {
          html
        }
        extraImage {
          url
        }
        extraImageAltText
        extraTitle
        hiddenLessonBody {
          html
        }
        hiddenLessonImage {
          url
        }
        hiddenLessonImageAltText
        hiddenLessonTitle
        path
        signUpSubtitle
        signUpTitle
        signUpLoggedSubtitle
        signUpLoggedTitle
        signUpTeamImages {
          items {
            image {
              url
            }
            altText
          }
        }
        steps {
          items {
            body {
              html
            }
            textSide {
              items {
                _system_ {
                  codename
                  name
                }
              }
            }
            image {
              url
            }
            imageAltText
            title
            titleStepSpan
          }
        }
        topTitle
        carouselIntervalInSeconds
        carousel(limit: 20) {
          items {
            title
            body {
              html
            }
            titleTextColor
            subtitleTextColor
            ctaAction
            ctaBackgroundColor
            ctaTextColor
            ctaTitle
            textAlignment {
              items {
                _system_ {
                  codename
                  name
                }
              }
            }
            title
            backgroundImage {
              url
            }
            backgroundMobilePosition {
              items {
                _system_ {
                  name
                  codename
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const formatKenticoCampaign2 = kenticoCampaign => {
  const general = {
    topTitle: kenticoCampaign.topTitle,
    campaignName: kenticoCampaign.campaignName,
    path: kenticoCampaign.path,
    backgroundColor: kenticoCampaign.backgroundColor
  };
  let carouselItems = kenticoCampaign.carousel.items.map(item => {
    return {
      image: item.backgroundImage.url,
      backgroundMobilePosition:
        item.backgroundMobilePosition.items[0]?._system_.codename,
      ctaAction: item.ctaAction,
      title: item.title,
      body: item.body.html,
      ctaBackgroundColor: item.ctaBackgroundColor,
      ctaTextColor: item.ctaTextColor,
      ctaTitle: item.ctaTitle,
      titleTextColor: item.titleTextColor,
      subtitleTextColor: item.subtitleTextColor,
      textAlignment: item.textAlignment?.items[0]?._system_?.name || ""
    };
  });

  let extra = {
    title: kenticoCampaign.extraTitle,
    body: kenticoCampaign.extraBody.html,
    imageAltText: kenticoCampaign.extraImageAltText,
    image: kenticoCampaign.extraImage.url
  };

  let hiddenLesson = {
    title: kenticoCampaign.hiddenLessonTitle,
    body: kenticoCampaign.hiddenLessonBody.html,
    image: kenticoCampaign.hiddenLessonImage.url,
    imageAltText: kenticoCampaign.hiddenLessonImageAltText
  };

  const howItWorks = kenticoCampaign.steps.items.map(item => {
    return {
      body: item.body.html,
      image: item.image.url,
      imageAltText: item.imageAltText,
      title: item.title,
      titleSpan: item.titleStepSpan,
      textAlignment: item.textSide.items[0]._system_.name
    };
  });

  let signUp = {
    title: kenticoCampaign.signUpTitle,
    subtitle: kenticoCampaign.signUpSubtitle,
    loggedSubtitle: kenticoCampaign.signUpLoggedSubtitle,
    loggedTitle: kenticoCampaign.signUpLoggedTitle,
    teamImages: kenticoCampaign.signUpTeamImages.items.map(item => {
      return {
        altText: item.altText,
        url: item.image.url
      };
    })
  };

  let banner = {
    ctaBackgroundColor: kenticoCampaign.bannerCtaBackgroundColor,
    ctaRedirection: kenticoCampaign.bannerCtaRedirection,
    ctaText: kenticoCampaign.bannerCtaText,
    ctaTextColor: kenticoCampaign.bannerCtaTextColor,
    image: kenticoCampaign.bannerImage.url,
    imageAltText: kenticoCampaign.bannerImageAltText,
    title: kenticoCampaign.bannerTitle,
    body: kenticoCampaign.bannerBody.html,
    textColor: kenticoCampaign.bannerTextColor
  };

  return {
    general,
    banner,
    carouselIntervalInSeconds: kenticoCampaign.carouselIntervalInSeconds || 0,
    carouselItems,
    hiddenLesson,
    howItWorks,
    signUp,
    extra
  };
};
