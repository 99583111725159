<template>
  <router-link
    class="relative team-image flex flex-col justify-end items-center text-white"
    :class="{
      'cursor-pointer': !isPlaceholder
    }"
    :to="goToTeam()"
  >
    <img :src="bgImage" alt="" class="rounded-md w-full" />
    <div
      v-if="!isPlaceholder"
      class="absolute bottom-shadow text-center rounded-b-md pt-8"
    >
      <h3 class="whitespace-pre-line block w-full" v-html="team.title"></h3>
      <p v-if="team.mainCaptain" class="text-xs mt-2 mb-10 sm:mb-24">
        Captain: {{ team.mainCaptain.name }}
      </p>
    </div>
    <Badge
      v-if="badge"
      :textColor="badge.textColor"
      :backgroundColor="badge.backgroundColor"
      :borderColor="badge.borderColor"
      :text="badge.text"
      :shape="badge.shape"
      :position="badge.position"
    />
  </router-link>
</template>
<script>
import Badge from "@/components/shared/Badge.vue";
import { mapGetters } from "vuex";
import TeamBadgeMixin from "@/mixins/TeamBadgeMixin.js";

export default {
  name: "DiscoverItem",
  components: {
    Badge
  },
  mixins: [TeamBadgeMixin],
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  computed: {
    isPlaceholder() {
      if (this.team.status.items && this.team.status.items.length > 0) {
        return this.team.status.items[0]._system_.codename === "placeholder";
      }
      return false;
    },
    ...mapGetters("general", ["isDesktop"]),
    bgImage() {
      // mobile background
      if (!this.isDesktop && this.team.imageMobile?.url) {
        return this.team.imageMobile.url;
      }
      // desktop background
      else return this.team.imageDesktop.url;
    }
  },
  methods: {
    goToTeam() {
      if (this.isPlaceholder) return {};
      return {
        name: "TeamPage",
        params: { slug: this.team.appsyncSlug }
      };
    }
  }
};
</script>
<style lang="postcss" scoped>
.bottom-shadow {
  width: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
}
@media screen and (min-width: 640px) {
  .team-image {
    min-height: 600px;
  }
}
</style>
