<template>
  <div class="h-full">
    <template v-if="appsyncTask && kenticoTask">
      <TaskJoinTeamModal
        v-model="showJoinTeam"
        :isMember="isMember"
        :teamName="teamName"
        :loading="joinTeamLoading"
        @joinTeam="joinTeam"
        @close="showJoinTeam = false"
      />
      <TaskCommitment
        v-if="
          standardTasks.includes(appsyncTask.taskType) &&
            kenticoTaskType === 'Commitment'
        "
        :kenticoTask="kenticoTask"
        :appsyncTask="appsyncTask"
        :isMember="isMember"
        :metaData="metaData"
        :shareData="shareData"
        @showJoinTeam="showJoinTeam = true"
      />
      <TaskStandard
        v-else-if="standardTasks.includes(appsyncTask.taskType)"
        :kenticoTask="kenticoTask"
        :appsyncTask="appsyncTask"
        :isMember="isMember"
        :metaData="metaData"
        :shareData="shareData"
        @taskCTA="handleTaskCTA()"
        @showJoinTeam="showJoinTeam = true"
      />
      <TaskDonorbox
        v-else-if="kenticoTaskType === 'Donorbox'"
        :appsyncTask="appsyncTask"
        :kenticoTask="kenticoTask"
        :isMember="isMember"
        :metaData="metaData"
        :shareData="shareData"
        @showJoinTeam="showJoinTeam = true"
      />
      <TaskDonation
        v-else-if="appsyncTask.taskType === 'DonationTask'"
        :appsyncTask="appsyncTask"
        :kenticoTask="kenticoTask"
        :isMember="isMember"
        :metaData="metaData"
        :shareData="shareData"
        @showJoinTeam="showJoinTeam = true"
      />
    </template>
    <template v-else-if="loading">
      <TaskPageSkeleton />
    </template>
    <template v-else>
      <div class="w-full h-full flex flex-col relative  md:pt-6">
        <NotFound
          :title="$t('message.task-not-found')"
          :description="$t('message.task-not-found-description')"
        />
      </div>
    </template>
  </div>
</template>
<script>
import TaskPageSkeleton from "@/components/skeletons/TaskPageSkeleton.vue";
import { JOIN_TEAM } from "@/graphql/queries/user/userMutations.js";
import { GET_TASK } from "@/graphql/queries/task/taskQueries.js";
import { GET_TASK_KENTICO } from "@/kentico-api/tasks.js";
import { seoMetadata } from "@/utils/seoMetadata";
import TaskStandard from "@/components/tasks/TaskStandard.vue";
import TaskDonation from "@/components/tasks/TaskDonation.vue";
import TaskDonorbox from "@/components/tasks/TaskDonorbox.vue";
import TaskCommitment from "@/components/tasks/TaskCommitment.vue";
import TaskJoinTeamModal from "@/components/tasks/TaskJoinTeamModal.vue";
import apolloErrorHandling from "@/utils/apolloErrorHandling.js";
import NotFound from "@/components/shared/NotFound";

export default {
  name: "TaskPage",
  components: {
    TaskPageSkeleton,
    TaskStandard,
    TaskDonation,
    TaskCommitment,
    TaskJoinTeamModal,
    NotFound,
    TaskDonorbox
  },
  data() {
    return {
      actionButtonBusy: false,
      showJoinTeam: false,
      joinTeamLoading: false,
      appsyncTask: null,
      teamName: "",
      loading: true,
      standardTasks: ["PledgeTask", "ReadTask", "WatchTask"],
      kenticoTaskType: null,
      metaData: {
        metaDescription: null,
        metaTitle: null,
        metaImageUrl: null,
        metaVideoUrl: null,
        keywords: null
      },
      tweetTemplateName: ""
    };
  },
  head: function() {
    return seoMetadata({
      fullPath: this.$route.fullPath,
      metaDescription: this.metaData.metaDescription,
      metaTitle: this.metaData.metaTitle,
      keywords: this.metaData.keywords,
      metaImageUrl: this.metaData.imageUrl,
      metaVideoUrl: this.metaData.videoUrl
    });
  },
  apollo: {
    kenticoTask: {
      client: "kenticoClient",
      query: GET_TASK_KENTICO,
      variables() {
        return {
          codename: this.appsyncTask.kenticoCodename
        };
      },
      update(data) {
        this.kenticoTaskType =
          data?.task?.taskType?.items[0]?._system_.name || null;
        const og = data.task._openGraph;
        this.metaData.metaTitle =
          og.ogTitle !== "" ? og.ogTitle : data.task.title;
        this.metaData.metaDescription =
          og.ogDescription !== ""
            ? og.ogDescription
            : data.task.introContent?.html;
        this.metaData.imageUrl =
          og.ogImage?.url && og.ogImage.url !== ""
            ? og.ogImage.url
            : data.task.introImage?.url;
        this.metaData.videoUrl =
          og.ogVideo !== "" ? og.ogVideo : data.task.introVideo?.url;
        this.$emit("updateHead");
        this.loading = false;
        if (data.task) return data.task;
      },
      skip() {
        return !this.appsyncTask;
      },
      //test if this property is needed
      fetchPolicy: "no-cache"
    },
    getTask: {
      query: GET_TASK,
      variables() {
        return {
          id: this.$route.params.taskId
        };
      },
      update(data) {
        if (data.getTask) {
          this.appsyncTask = data.getTask;
          this.teamName = data.getTask.team.name;
          return data.getTask;
        }
      },
      //test if this property is needed
      fetchPolicy: "no-cache"
    }
  },
  computed: {
    shareData() {
      if (!this.appsyncTask?.id || !this.appsyncTask?.team?.slug) return false;

      return {
        task: {
          taskId: this.appsyncTask?.id || "",
          teamSlug: this.appsyncTask?.team?.slug || ""
        }
      };
    },
    isMember() {
      return this.appsyncTask.team.member;
    }
  },
  methods: {
    joinTeam() {
      this.joinTeamLoading = true;
      this.$apollo
        .mutate({
          mutation: JOIN_TEAM,
          variables: {
            teamId: this.appsyncTask.teamId
          },
          update: (data, data2) => {
            if (data2.data.joinTeam == true) {
              this.appsyncTask.team.member = true;
              this.joinTeamLoading = false;
              this.$analytics.joinTeam(this.appsyncTask.team);
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          apolloErrorHandling(graphQLErrors);
          this.joinTeamLoading = false;
        });
    }
  }
};
</script>
