<template>
  <RegisterContainer
    :header="$t('message.create-a-password')"
    :loading="loading"
    @next="createPassword()"
  >
    <InputWrapper
      class="mt-4"
      name="password"
      :label="$t('message.password')"
      type="password"
      :error="hasError('password')"
      :hint="hint('password')"
      :touched="touched('password')"
      :placeholder="$t('message.password')"
      v-model="password"
      @input="validateForm()"
      @blur="changed('password')"
    />
    <InputWrapper
      class="mt-4"
      name="repeatPassword"
      :label="$t('message.repeat-password')"
      type="password"
      :error="hasError('repeatPassword')"
      :touched="touched('repeatPassword')"
      :placeholder="$t('message.password')"
      :hint="hint('repeatPassword')"
      v-model="repeatPassword"
      @input="validateForm()"
      @blur="changed('repeatPassword')"
    />
    <PasswordRules class="mt-4" />
    <div class="mt-8 text-red text-center" v-if="response">
      {{ response }}
    </div>
  </RegisterContainer>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import PasswordRules from "@/components/shared/PasswordRules.vue";
import RegisterContainer from "@/components/register/RegisterContainer.vue";
import { customPasswordValidator } from "@/utils/basicHelpers.js";

export default {
  name: "CreatePassword",
  components: {
    InputWrapper,
    PasswordRules,
    RegisterContainer
  },
  data: () => {
    return {
      loading: false,
      error: "",
      password: "",
      repeatPassword: "",
      validation: {},
      showAllErrors: false,
      response: null,
      step: null
    };
  },
  beforeMount() {
    this.step = this.$route.meta.step;
    if (
      this.$route.name != this.$store.getters["auth/getNewUserValidLocation"] &&
      this.$store.getters["auth/getNewUserStep"] < this.step - 1
    ) {
      this.$router.replace({
        name: this.$store.getters["auth/getNewUserValidLocation"]
      });
    }
  },
  computed: {
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint ||
              this.validation[key]?.initialHint ||
              ""
          : this.validation[key]?.initialHint || "";
      };
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        console.log("t1");
        this.validation[key] = { ...this.validation[key], touched: true };
        console.log({ ...this.validation[key], touched: true });
      }
      console.log("newValidation", this.validation[key]);
      this.showAllErrors = true;
      console.log("change", key);
      this.validateForm();
      this.$forceUpdate();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      if (this.password == "") {
        this.addError("password", "The password can't be empty");
        valid = false;
      } else {
        this.clearError("password");
      }
      if (!customPasswordValidator(this.password)) {
        this.addError("password", "Password is not strong enough");
        valid = false;
      } else {
        this.clearError("password");
      }
      if (this.password != this.repeatPassword) {
        this.addError(
          "repeatPassword",
          "This password should match with the one before"
        );
        valid = false;
      } else {
        this.clearError("repeatPassword");
      }
      return valid;
    },
    async createPassword() {
      this.response = null;
      this.showAllErrors = true;
      const valid = this.validateForm();
      if (valid) {
        const emailCache = this.$store.getters["auth/getNewUserEmail"];
        const passwordCache = this.password;
        this.loading = true;
        let created = await this.$store.dispatch(
          "auth/createUserAccount",
          this.password
        );
        //exceptions to handle
        /**
         * InvalidPasswordException
         * UsernameExistsException
         * */

        if (created !== true) {
          this.response = created.message;
        } else {
          this.$router.push({
            name: "EnterCode",
            params: {
              emailCache,
              passwordCache
            },
            query: this.$route.query
          });
        }
        this.loading = false;
      }
    }
  }
};
</script>
