<template>
  <div class="banner">
    <div class="slider relative">
      <div ref="slide-box" id="slide-box" class="slides relative">
        <CarouselItem
          class="item bg-red-400 bg-cover bg-center"
          v-for="item in items"
          :item="item"
          :key="item.id"
        >
        </CarouselItem>
      </div>
      <div class="arrow-button-box left" v-if="items.length > 1">
        <button class="arrow-button" @click="goToPrevManual">
          <img src="@/assets/img/icons/carousel-arrow.svg" alt="prev arrow" />
        </button>
      </div>
      <div class="arrow-button-box right" v-if="items.length > 1">
        <button class="arrow-button" @click="goToNextManual">
          <img src="@/assets/img/icons/carousel-arrow.svg" alt="next arrow" />
        </button>
      </div>
      <div
        class="flex flex-row absolute bottom-4 w-full justify-center items-center gap-1"
      >
        <button
          v-for="item in items"
          :key="item.id"
          @click="goToIndex(item.id)"
          :class="{ 'active-dot': activeIndex === item.id }"
          class="dot"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CarouselItem from "./CarouselItem.vue";
export default {
  data() {
    return {
      items: [],
      activeIndex: 0,
      blocked: true,
      interval: null
    };
  },
  components: {
    CarouselItem
  },
  props: {
    carouselIntervalInSeconds: {
      type: Number,
      required: true
    },
    carouselItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    startInterval() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.goToNext();
      }, this.carouselIntervalInSeconds * 1000);
    },
    goToIndex(index) {
      this.startInterval();
      this.activeIndex = index;
      const itemToScroll = document.getElementById(
        `slide-${this.items[this.activeIndex].id}`
      );
      const slideBox = this.$refs["slide-box"];
      const left = itemToScroll.offsetLeft;
      slideBox.scrollTo({
        left: left
      });
    },
    goToActiveIndex() {
      const itemToScroll = document.getElementById(
        `slide-${this.items[this.activeIndex].id}`
      );
      const slideBox = this.$refs["slide-box"];
      const left = itemToScroll.offsetLeft;
      slideBox.scrollTo({
        left: left
      });
    },
    goToPrev() {
      if (this.activeIndex === 0) {
        this.activeIndex = this.items.length - 1;
      } else {
        this.activeIndex--;
      }
      this.goToActiveIndex();
    },
    goToNext() {
      if (this.activeIndex === this.items.length - 1) {
        this.activeIndex = 0;
      } else {
        this.activeIndex++;
      }
      this.goToActiveIndex();
    },
    goToPrevManual() {
      this.startInterval();
      this.goToPrev();
    },
    goToNextManual() {
      this.startInterval();
      this.goToNext();
    }
  },
  beforeMount() {
    let i = -1;
    this.items = this.carouselItems.map(item => {
      i++;
      return {
        id: i,
        ...item
      };
    });
  },
  mounted() {
    if (this.carouselIntervalInSeconds === 0) return;

    this.startInterval();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>
<style scoped>
.arrow-button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
}
.dot {
  @apply bg-white shadow-lg rounded-full;

  border: 2px solid #ededed;
  background: #f5f5f5;
  width: 21px;
  height: 21px;
}

.active-dot {
  @apply bg-black;

  border: 2px solid #5b5b5b;
}
.arrow-button-box.left {
  left: 10px;
}
.arrow-button-box.right {
  right: 10px;
  transform: rotate(3.142rad);
}
.arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-radius: 100%;
}
button.arrow-button {
  width: 50px;
  height: 50px;
}
.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.slides::-webkit-scrollbar-thumb {
  display: none;
  visibility: hidden;
}
.slides::-webkit-scrollbar {
  display: none;
  visibility: hidden;
}
.slides::-moz-scrollbar {
  display: none;
  visibility: hidden;
}
.slides > .item {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  margin-right: 0px;
  border-radius: 0px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 100px;
  height: 600px;
}
.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  background: #000;
}
</style>
