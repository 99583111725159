<template>
  <div
    class="item bg-red-400 bg-cover"
    :id="`slide-${item.id}`"
    :key="item.id"
    :style="`background-image: url(${item.image});${backgroundStyle}`"
  >
    <div class="w-full md:w-6/12 flex flex-col justify-center px-20">
      <h2
        v-if="item.title !== ''"
        class="mb-5 text-block"
        :style="computedTitleStyle"
      >
        {{ item.title }}
      </h2>
      <div
        class="text-black text-xl text-block"
        :style="computedSubTitleStyle"
        v-if="item.body && item.body !== ''"
        v-html="item.body"
      ></div>
      <router-link
        class="cta-btn btn mt-5 text-center"
        :style="{
          backgroundColor: item.ctaBackgroundColor,
          color: item.ctaTextColor
        }"
        :to="item.ctaAction"
      >
        {{ item.ctaTitle }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundStyle() {
      let style = "";

      if (this.item.backgroundMobilePosition) {
        style += `background-position: ${this.item.backgroundMobilePosition};`;
      }

      return style;
    },
    computedTitleStyle() {
      let style = "";

      if (this.item.titleTextColor !== "") {
        style += `color: ${this.item.titleTextColor}`;
      }
      return style;
    },
    computedSubTitleStyle() {
      let style = "";

      if (this.item.subtitleTextColor !== "") {
        style += `color: ${this.item.subtitleTextColor}`;
      }
      return style;
    }
  }
};
</script>
<style lang="postcss" scoped>
.text-block {
  @apply mx-auto text-center;

  max-width: 506px;
}
.cta-btn {
  @apply px-4 mx-auto flex items-center justify-center;

  width: fit-content;
}

h2 {
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.96px;
}
</style>
