<template>
  <div class="w-full">
    <div
      :class="{ reversed: step.textAlignment === 'left' }"
      class="flex
             flex-col
             md:grid
             md:grid-cols-2
             px-4
             md:px-32
             gap-8
             py-24
             max-w-wide-desktop
             mx-auto
             card
             "
    >
      <div class="flex justify-center items-center">
        <img
          style="max-width: 300px;"
          :src="step.image"
          :alt="step.imageAltText"
        />
      </div>
      <div class="flex flex-col text-box">
        <h2 class="text-black mb-2">
          <span style="color: #FF5A00;">
            {{ step.titleSpan }}
          </span>
          {{ step.title }}
        </h2>
        <div v-html="step.body"></div>
        <router-link
          class="capitalize btn btn-default mt-6 max-btn w-full md:w-52"
          :to="{ name: 'SignUpEmailForm' }"
        >
          {{ $t("message.get-started") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Object,
      required: true
    }
  }
};
</script>
<style scoped>
.card {
  @media screen and (max-width: 767px) {
    text-align: center;

    a {
      @apply mx-auto;
    }
  }
}
.card.reversed {
  @media screen and (min-width: 768px) {
    direction: rtl;
    .text-box {
      text-align: left;
      max-width: 450px;
    }
    a {
      margin-right: auto;
    }
  }
}
</style>
