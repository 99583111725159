<template>
  <div class="banner" :style="computedMainStyle">
    <div
      class="content-box
             w-full
             mx-0
             px-4
             lg:px-0
             lg:mx-20
             h-full
             flex
             flex-col
             text-left
             lg:w-2/6"
    >
      <h2 class="mb-4">
        {{ banner.title }}
      </h2>
      <div class="body" v-html="banner.body"></div>
    </div>
    <router-link :to="banner.ctaRedirection" :style="computedCtaStyle">
      {{ banner.ctaText }}
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  computed: {
    computedMainStyle() {
      if (!this.banner) return "";

      let style = "";
      if (this.banner.image && this.banner.image !== "") {
        style = style + `background-image: url(${this.banner.image});`;
      }
      if (this.banner.textColor && this.banner.textColor !== "") {
        style = style + `color: ${this.banner.textColor}`;
      }
      return style;
    },
    computedCtaStyle() {
      if (!this.banner) return "";

      let style = "";

      if (this.banner.ctaTextColor && this.banner.ctaTextColor !== "") {
        style = style + `color: ${this.banner.ctaTextColor};`;
      }
      if (
        this.banner.ctaBackgroundColor &&
        this.banner.ctaBackgroundColor !== ""
      ) {
        style = style + `background-color: ${this.banner.ctaBackgroundColor}`;
      }
      return style;
    }
  }
};
</script>
<style lang="postcss" scoped>
.banner {
  @apply flex flex-col bg-center bg-cover relative justify-center;
  min-height: 675px;
}
a {
  @apply absolute
         left-auto
         right-auto
         bottom-16
         rounded-full
         px-4
         py-2
         uppercase
         flex
         justify-center
         items-center
         text-center;

  left: calc(50% - 160px);
  right: calc(50% - 160px);
  width: 320px;
  height: 84px;
  font-size: 25px;
}
.content-box {
  max-width: 400px;
}

h2 {
  font-size: 42px;
  font-weight: 700;
  text-shadow: 0px 4px 10px rgb(0 0 0 / 50%);
}

.body {
  font-size: 22px;
  text-shadow: 0px 4px 10px rgb(0 0 0 / 50%);
}
</style>
